import breakpoints from 'constants/breakpoints';
import margins from 'constants/margins';

import styled, {css} from 'styled-components';

import {ArrowLeft as ArrowLeftIcon} from '~public/assets/icons';

export const Container = styled.div`
    position: relative;
    ${margins};
    margin-bottom: 80px !important;

    &::before {
        content: '';
        position: absolute;
        width: 100%;
        background-size: contain;
        aspect-ratio: 1440 / 1183;
        z-index: -1;
        top: -200px;
    }

    & > div:first-child {
        margin-bottom: 50px;
    }
`;

export const Header = styled.div`
    display: flex;
    align-items: center;
    gap: 30px;
    margin: 0 auto;
    justify-content: center;
    text-align: center;

    ${breakpoints.phoneS} {
        padding: 0 15px;
        gap: 15px;
    }

    ${breakpoints.abovePhoneS} {
        gap: 60px;
    }

    ${breakpoints.aboveDesktop} {
        gap: 100px;
    }

    .section-name {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-weight: lighter;
        cursor: pointer;

        b {
            font-weight: 500;
        }
    }
`;

export const SliderContainer = styled.div`
    margin-top: 40px;
    width: 100%;
    position: relative;

    .slick-track {
        display: flex;
    }

    .slick-slide {
        & > div {
            padding: 0 8vw;
        }
    }
`;

const Arrow = styled(ArrowLeftIcon)<{disabled?: boolean}>`
    z-index: 2;
    position: absolute;
    top: 50%;
    --fromEdge: max(calc(25vw - 250px), 16px);

    path {
        stroke: ${({disabled}) => disabled && 'gray'};
    }

    cursor: ${({disabled}) => (disabled ? 'default' : 'pointer')};

    ${breakpoints.tablet} {
        display: none !important;
    }
`;

export const ArrowLeft = styled(Arrow)`
    transform: translateY(-50%);
    left: var(--fromEdge);
`;
export const ArrowRight = styled(Arrow)`
    right: var(--fromEdge);
    transform: translateY(-50%) scale(-1, -1);
`;

export const RoadmapWrapper = styled.div`
    width: 100%;
    height: 520px;
`;

type RoadmapContainerProps = {
    width: number;
};

export const RoadmapContainer = styled.div<RoadmapContainerProps>`
    /* width: ${({width}) => width && `${width}px`}; */
    width: 100%;
    overflow: scroll;
    height: 520px;
    position: relative;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
`;

type RoadmapElementProps = {
    isOdd: boolean;
    numberOfElement: number;
};

export const RoadmapElementContainer = styled.div<RoadmapElementProps>`
    z-index: 2;
    width: 183px;
    padding: 20px;
    position: absolute;
    left: ${({numberOfElement}) =>
        `${numberOfElement * 187 + 16 * numberOfElement + 8}px`};
    border-radius: 5px;
    transition: 0.3s;

    ${({isOdd}) =>
        isOdd
            ? css`
                  bottom: 0;
              `
            : css`
                  top: 0;
              `}
    &:hover {
        background: rgba(255, 255, 255, 0.1);
    }
`;

type DotProps = {
    isOdd: boolean;
    lastActive: boolean;
};

type DotPositionWrapperProps = {
    lastActive?: boolean;
    indexOfLastActive: number;
    numberOfElement: number;
};

export const DotPositionWrapper = styled.div<DotPositionWrapperProps>`
    position: absolute;
    width: ${({lastActive}) => (lastActive ? `${22}px` : `${16}px`)};
    height: ${({lastActive}) => (lastActive ? `${22}px` : `${16}px`)};
    top: 50%;
    left: ${({numberOfElement}) =>
        `${numberOfElement * 187 + 16 * numberOfElement + 20}px`};
    transform: translateY(-50%);

    ${({numberOfElement, indexOfLastActive}) => {
        if (numberOfElement < indexOfLastActive) {
            css<{numberOfElement: number; indexOfLastActive: number}>`
                left: ${({numberOfElement}) =>
                    `${numberOfElement * 187 + 16 * numberOfElement + 20}px`};
            `;
        }
        if (numberOfElement === indexOfLastActive + 1) {
            return css<{numberOfElement: number; indexOfLastActive: number}>`
                left: ${({numberOfElement}) =>
                    `${numberOfElement * 187 + 16 * numberOfElement + 20 + 6}px`};
            `;
        }
        if (numberOfElement === indexOfLastActive) {
            return css<{numberOfElement: number; indexOfLastActive: number}>`
                left: ${({numberOfElement}) =>
                    `${numberOfElement * 187 + 16 * numberOfElement + 20 - 6}px`};
            `;
        }
    }}
`;

export const Dot = styled.div<DotProps>`
    width: ${({lastActive}) => (lastActive ? `${22}px` : `${16}px`)};
    height: ${({lastActive}) => (lastActive ? `${22}px` : `${16}px`)};
    background: #00031b;
    border: ${({lastActive}) => (lastActive ? `${3}px` : `${2}px`)} solid
        ${({isOdd}) => (isOdd ? '#EE4492' : '#414fa2')};
    box-shadow: 0 0 6px ${({isOdd}) => (isOdd ? '#6F4D9E' : '#0839e7')};
    position: relative;
    border-radius: 50%;

    &::after {
        content: '';
        position: absolute;
        width: 8px;
        height: 8px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        z-index: 2;
        background: ${({isOdd}) => (isOdd ? '#EE4492' : '#414fa2')};
    }
`;

type EclipseProps = {
    numberOfElement: number;
    indexOfLastActive: number;
};

export const SmallEclipseTopWrapper = styled.div<EclipseProps>`
    position: absolute;
    left: ${({numberOfElement}) =>
        `${numberOfElement * 187 + 16 * numberOfElement + 20 + 8}px`};
    top: calc(50% - 10.5px);
    transform: translateY(-100%);
    display: ${({numberOfElement, indexOfLastActive}) =>
        numberOfElement === indexOfLastActive ? 'none' : 'block'};
`;

export const SmallEclipseBottomWrapper = styled.div<EclipseProps>`
    position: absolute;
    left: ${({numberOfElement}) =>
        `${numberOfElement * 187 + 16 * numberOfElement + 20 + 6}px`};
    top: calc(50% + 10.5px);
    transform: ${({numberOfElement, indexOfLastActive}) =>
        numberOfElement < indexOfLastActive
            ? 'rotate(180deg) translateX(-4px)'
            : 'rotate(180deg) translateX(-6px)'};
    display: ${({numberOfElement, indexOfLastActive}) =>
        numberOfElement === indexOfLastActive ? 'none' : 'block'};
`;

export const BigEclipseTopWrapper = styled.div<EclipseProps>`
    position: absolute;
    left: ${({numberOfElement}) =>
        `${numberOfElement * 187 + 16 * numberOfElement + 20 + 4}px`};
    top: calc(50% - 10.5px);

    ${({numberOfElement}) => {
        return numberOfElement % 2 === 0
            ? css`
                  transform: translateY(-100%) rotate3d(0, 1, 0, 180deg);
              `
            : css`
                  transform: translateY(-100%);
              `;
    }}
`;

export const BigEclipseBottomWrapper = styled.div<EclipseProps>`
    position: absolute;
    left: ${({numberOfElement}) =>
        `${numberOfElement * 187 + 16 * numberOfElement + 20 + 6}px`};
    top: calc(50% + 10.5px);
    ${({numberOfElement}) => {
        return numberOfElement % 2 === 0
            ? css`
                  transform: rotate(180deg) rotate3d(0, 1, 0, 180deg);
              `
            : css`
                  transform: rotate(180deg);
              `;
    }}
`;

type LineProps = {
    width: number;
};

export const Line = styled.div<LineProps>`
    position: absolute;
    width: ${({width}) => `${width}px`};
    top: 50%;
    transform: translateY(-50%);
    height: 8px;
    background: linear-gradient(88.2deg, #d84594 42.72%, #574ea0 45.28%);
    opacity: 0.1;
`;

export const LineContainer = styled.div`
    width: 100%;
    position: absolute;
    height: 200px;
    overflow: scroll;
    background-color: rgba(255, 255, 0, 0.1);
    top: 160px;
`;

export const LineWrapper = styled.div<LineProps>`
    position: relative;
    width: 100%;
    height: 100%;
    overflow: scroll;
`;

type ScrollBlockProps = {
    top?: boolean;
    bottom?: boolean;
};

export const ScrollBlock = styled.div<ScrollBlockProps>`
    width: 100%;
    height: 200px;
    position: absolute;
    top: ${({top}) => top && '200px'};
    bottom: ${({bottom}) => bottom && '75px'};
`;

export const RoadmapPagination = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 106px;
    gap: 20px;
`;

type DotPaginationProps = {
    active?: boolean;
};

export const DotPagination = styled.div<DotPaginationProps>`
    width: 8px;
    height: 8px;
    border-radius: 50%;
    cursor: pointer;
    background: ${({active}) =>
        active ? 'rgba(255, 255, 255, 0.8)' : 'rgba(255, 255, 255, 0.4)'};
`;
