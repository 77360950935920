import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'next-i18next';

import Text from '~components/atoms/Text';
import TextContainer from '~components/atoms/TextContainer';
import {
    PageQueryPageDataAttributesContentComponentSectionRoadmap,
    PageQueryPageDataAttributesContentComponentSectionRoadmapBlocksBlocks,
} from '~interfaces/graphql/PageQuery';
import useDocumentSize from '~hooks/useDocumentSize';

import {
    Container,
    DotPagination,
    Line,
    RoadmapContainer,
    RoadmapPagination,
    ScrollBlock,
} from './styles';
import RoadmapElement from './RoadmapElement';

export function useHorizontalScroll() {
    const elRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const el = elRef.current;
        if (el) {
            const onWheel = (e: any) => {
                if (e.deltaY === 0) return;
                e.preventDefault();
                el.scrollTo({
                    left: el.scrollLeft + e.deltaY,
                    // behavior: 'smooth',
                });
            };
            el.addEventListener('wheel', onWheel);
            return () => el.removeEventListener('wheel', onWheel);
        }
    }, []);
    return elRef;
}

type RoadmapProps = {
    data: PageQueryPageDataAttributesContentComponentSectionRoadmap;
};

const Roadmap = ({
    data: {blocks: quarters, sectionName, sectionTitle},
}: RoadmapProps) => {
    const {t} = useTranslation('common');
    const {width} = useDocumentSize();
    const roadmapQuaters = useMemo(() => {
        const arr: PageQueryPageDataAttributesContentComponentSectionRoadmapBlocksBlocks[][] =
            [];
        quarters.map((item) => arr.push(item.blocks));
        return arr;
    }, [quarters]);

    const roadmapItems = useMemo(() => {
        const arr: PageQueryPageDataAttributesContentComponentSectionRoadmapBlocksBlocks[] =
            [];
        roadmapQuaters.map((item) => item.map((item) => arr.push(item)));
        return arr;
    }, [quarters]);

    const roadmapScrollPosition = useMemo(() => {
        if (width > 1000) {
            return width * (550 / width);
        }
        if (width < 500) {
            return width * (10 / width);
        }
        return width * (100 / width);
    }, [width]);

    const indexOfLastActiveElement = useMemo(() => {
        let lastActive = 0;
        roadmapItems.map((item, index) => {
            if (item.type === 'done') {
                if (roadmapItems[index + 1]?.type !== 'done') {
                    lastActive = index;
                }
            }
        });
        return lastActive;
    }, [roadmapItems]);

    const scrollRef = useHorizontalScroll();

    const pagination = useMemo(() => {
        const tab: number[] = [];
        const lastElementTab: number[] = [];
        roadmapItems.map((item, index) => tab.push(index * 187 + 16 * index + 8));
        tab.map((item) => {
            if (item > width * lastElementTab.length) {
                lastElementTab.push(item);
            }
        });
        return lastElementTab;
    }, [roadmapItems, width]);

    const [paginationIndex, setPaginationIndex] = useState(0);

    return (
        <Container>
            <TextContainer size="small">
                <Text color="pink" type="sectionName">
                    {sectionName}
                </Text>
                <Text color="white" type="sectionTitle">
                    {sectionTitle}
                </Text>
                <Text color="white" type="content">
                    {t('roadmapText')}
                </Text>
            </TextContainer>
            <RoadmapContainer ref={scrollRef} width={roadmapItems.length * 207}>
                {roadmapItems.map((item, index) => (
                    <RoadmapElement
                        key={index}
                        currentIndex={index}
                        indexOfLastActiveElement={indexOfLastActiveElement}
                        roadmapScrollPosition={roadmapScrollPosition}
                        container={scrollRef}
                        {...item}
                    />
                ))}

                <Line width={roadmapItems.length * 207} />
            </RoadmapContainer>
            <RoadmapPagination>
                {pagination.map((item, index) => {
                    return index >= 10 ? null : (
                        <DotPagination
                            active={index === paginationIndex}
                            onClick={() => {
                                setPaginationIndex(index);
                                scrollRef.current?.scrollTo({
                                    left: item,
                                    behavior: 'smooth',
                                });
                            }}
                        />
                    );
                })}
            </RoadmapPagination>
            <ScrollBlock top />
            <ScrollBlock bottom />
        </Container>
    );
};

export default Roadmap;
