import React, {useCallback, useMemo} from 'react';

import BigEclipse from '~assets/icons/big-eclipse.svg';
import SmallEclipse from '~assets/icons/small-eclipse.svg';
import Text from '~components/atoms/Text';
import {PageQueryPageDataAttributesContentComponentSectionRoadmapBlocksBlocks} from '~interfaces/graphql/PageQuery';
import DoneIcon from '~assets/icons/doneIcon.svg';
import ProgessIcon from '~assets/icons/progressIcon.svg';

import {
    BigEclipseBottomWrapper,
    BigEclipseTopWrapper,
    Dot,
    DotPositionWrapper,
    RoadmapElementContainer,
    SmallEclipseBottomWrapper,
    SmallEclipseTopWrapper,
} from '../styles';

type RoadmapElementProps = {
    currentIndex: number;
    indexOfLastActiveElement: number;
    roadmapScrollPosition: number;
    container: React.RefObject<HTMLDivElement>;
} & PageQueryPageDataAttributesContentComponentSectionRoadmapBlocksBlocks;

const RoadmapElement = ({
    currentIndex,
    indexOfLastActiveElement,
    title,
    container,
    roadmapScrollPosition,
    type,
}: RoadmapElementProps) => {
    const isOdd = useMemo(() => {
        return currentIndex % 2 !== 0;
    }, []);

    const isLastActiveElement = useMemo(() => {
        return indexOfLastActiveElement === currentIndex;
    }, []);

    const beforeLastActive = useMemo(() => {
        return currentIndex < indexOfLastActiveElement;
    }, []);

    const afterLastActive = useMemo(() => {
        return currentIndex > indexOfLastActiveElement;
    }, []);

    const scrollAmmount = useMemo(() => {
        return currentIndex * 187 + 16 * currentIndex + 8 - roadmapScrollPosition;
    }, [currentIndex, roadmapScrollPosition]);

    const scroll = useCallback(() => {
        container?.current?.scrollTo({
            left: scrollAmmount,
            behavior: 'smooth',
        });
    }, [scrollAmmount]);
    return (
        <>
            <RoadmapElementContainer
                numberOfElement={currentIndex}
                isOdd={Boolean(isOdd)}
                onClick={() => scroll()}
            >
                <Text color="white" bold type="content">
                    {/* <Logo style={{width: 20}} /> */}
                    {type === 'done' ? <DoneIcon /> : <ProgessIcon />}
                </Text>
                <Text color="rgba(255, 255,255, 0.6)" type="content">
                    <div dangerouslySetInnerHTML={{__html: title}} />
                </Text>
            </RoadmapElementContainer>
            <DotPositionWrapper
                lastActive={isLastActiveElement}
                indexOfLastActive={indexOfLastActiveElement}
                numberOfElement={currentIndex}
            >
                <Dot lastActive={isLastActiveElement} isOdd={Boolean(isOdd)} />
            </DotPositionWrapper>
            {((!isOdd && beforeLastActive) || (afterLastActive && isOdd)) && (
                <SmallEclipseTopWrapper
                    numberOfElement={currentIndex}
                    indexOfLastActive={indexOfLastActiveElement}
                >
                    <SmallEclipse />
                </SmallEclipseTopWrapper>
            )}
            {((isOdd && beforeLastActive) || (afterLastActive && !isOdd)) && (
                <SmallEclipseBottomWrapper
                    numberOfElement={currentIndex}
                    indexOfLastActive={indexOfLastActiveElement}
                >
                    <SmallEclipse />
                </SmallEclipseBottomWrapper>
            )}
            {isLastActiveElement && (
                <>
                    <BigEclipseTopWrapper
                        numberOfElement={currentIndex}
                        indexOfLastActive={indexOfLastActiveElement}
                    >
                        <BigEclipse />
                    </BigEclipseTopWrapper>
                    <BigEclipseBottomWrapper
                        numberOfElement={currentIndex}
                        indexOfLastActive={indexOfLastActiveElement}
                    >
                        <BigEclipse />
                    </BigEclipseBottomWrapper>
                </>
            )}
        </>
    );
};

export default RoadmapElement;
