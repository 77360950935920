import {css} from 'styled-components';

import breakpoints from './breakpoints';

const margins = css`
    margin: 100px 0 150px 0;
    ${breakpoints.aboveTablet} {
        margin: 100px 0 200px 0;
    }
`;

export default margins;
