import DolarIcon from './dolar.svg';
import EverdomeIcon from './everdome-logo.svg';
import EverdomeIconBig from './everdome-logo-big.svg';
import MessageIcon from './message.svg';
import StarIcon from './star.svg';
import TelegramIcon from './telegram.svg';
import TwitterIcon from './twitter.svg';
import ArrowLeftIcon from './arrow-left.svg';
import ArrowRightIcon from './arrow-left.svg';

export const Dolar = DolarIcon;
export const Everdome = EverdomeIcon;
export const EverdomeBig = EverdomeIconBig;
export const Message = MessageIcon;
export const Star = StarIcon;
export const Telegram = TelegramIcon;
export const Twitter = TwitterIcon;
export const ArrowLeft = ArrowLeftIcon;
export const ArrowRight = ArrowRightIcon;
